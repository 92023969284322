import SEO from '@/components/seo'
import Wrapper from '@/components/wrapper'
import { getData } from '@/lib/api'
import 'dayjs/locale/fr'
import type { GetStaticPaths, GetStaticProps, NextPage } from 'next'
import dynamic from 'next/dynamic'
import * as React from 'react'
import Menu from '@/components/navBar'
import { getStrapiImage } from '../../lib/api'
const Speakers = dynamic(import('@/components/speakers'))
const Footer = dynamic(import('@/components/footer'))
const Conditions = dynamic(import('@/components/conditions'))
const Sponsors = dynamic(import('@/components/sponsors'))
const Program = dynamic(import('@/components/program'))
const Count = dynamic(import('@/components/count'))
const Winners = dynamic(import('@/components/winners'))

interface Props {
  edition: Edition
  subMenu: SubMenu[]
  company: Company
}

const Edition: NextPage<Props> = ({ edition, subMenu, company }) => {
  return (
    <Wrapper>
      <SEO
        title="UpDev Challenge"
        description={company?.description}
        name="UpDev Challenge"
        logo={getStrapiImage(company?.logo)}
        favicon="/favicon.png"
        image={getStrapiImage(company?.cover)}
        phone="00000000"
        GA_MEASUREMENT_ID="0000000000"
      />
      <Menu
        subMenu={subMenu}
        logo={company?.logo}
        soumettre={edition?.banniere?.soumettre}
      />
      <Conditions data={edition?.conditions} />
      <Speakers data={edition?.speakers} />
      <Winners data={edition?.gagnants} />
      <Program data={edition?.programme} />
      <Count data={edition?.participants} />
      <Sponsors data={edition?.sponsors} />
      <Footer data={company} soumettre={edition?.banniere?.soumettre} />
    </Wrapper>
  )
}

export const getStaticPaths: GetStaticPaths = async () => {
  const edition = await getData(
    '/editions?populate=deep&filters[accueil][$eq]=false'
  )
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parse = edition?.data?.map((sub: any) => {
    return {
      params: {
        id: `${sub?.id}`,
      },
    }
  })

  return {
    paths: parse,
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps = async (context) => {
  const { params } = context
  console.log('params', params)
  const edition = await getData(
    `/editions/${params?.id}?populate=deep&filters[accueil][$eq]=true`
  )
  const subMenu = await getData(
    '/editions?populate=deep&filters[accueil][$eq]=false'
  )
  const company = await getData('/company?populate=deep')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parse = subMenu?.data?.map((sub: any) => {
    return { label: sub?.attributes?.nom, path: sub?.id }
  })

  return {
    props: {
      edition: edition?.data?.attributes,
      subMenu: parse,
      company: company?.data?.attributes,
    },
    revalidate: 10,
  }
}

export default Edition
